/* CodeGenarate.css */
.chat-input {
    position: fixed;
    bottom: 50px;
    width: 60%;
    margin-left: 10%;
}

.chat-input input {
    width: 80%;
}

.chat-input button {
    width: 20%;
}

.chat-history {
    max-height: 500px; /* Adjust this value as needed */
    overflow-y: auto;
}