.functional-requirements,
.middle-preview,
.right-preview {
  border: 1px dashed #ccc; /* 更改边框样式为虚线，颜色为淡灰色 */
  margin: 10px; /* 添加外部间隔 */
  padding: 10px; /* 添加内部间隔 */
}

/* 假设左中右区域的宽度比例为 2:3:5 */
.functional-requirements {
  flex: 2;
}

.middle-preview {
  flex: 3;
}

.right-preview {
  flex: 5;
}

/*!* 确保父元素是一个 flex 容器 *!*/
/*section {*/
/*  display: flex;*/
/*}*/