.layout {
    display: flex !important;
    /*min-height: 100vh; !* 确保占满整个视口高度 *!*/
}

.sidebar {
    /* 侧边栏的样式 */
    z-index: 1; /* 确保它在内容之上，但是不会覆盖内容 */
    /*width: 200px; !* 展开状态的宽度 *!*/
    min-width: 50px; /* 收起状态的最小宽度 */
    transition: width 0.3s; /* 宽度变化时的过渡效果 */
}

.main-content {
    flex-grow: 1; /* 占据剩余的空间 */
    margin-left: 50px !important; /* 与侧边栏宽度相同，保持一致 */
    transition: margin-left 0.3s; /* 过渡效果 */
    /* 当侧边栏收起时，调整左边距 */
}

/* 侧边栏收起时的样式 */
.sidebar-collapsed {
    width: 50px; /* 仅显示图标 */
}

/* 内容区域侧边栏收起时的样式 */
.main-content-collapsed {
    margin-left: 50px; /* 调整左边距以适应收起的侧边栏 */
}
