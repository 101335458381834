.register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.card-style {
    width: 380px;
    padding: 20px;
}