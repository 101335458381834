.header {
    display: flex;
    justify-content: space-between;
    height: 50px;
    padding: 10px 20px;
    /*background-color: #f5f5f5;*/
    border-bottom: 1px solid #e5e5e5;
    text-align: center;
}

.navbar-links {
    /* Add your styles for navbar links here */
}

.navbar-user-info {
    display: flex;
    align-items: center;
}
.logo {
    height: 50px;
    margin-right: 20px;
}