/* 父元素 */
.container {
    display: flex;
    justify-content: space-between;
}

/* 子元素 */
.canvas-widget, .code-viewer {
    width: 50%;  /* 调整为适合你的布局 */
}

.canvas-widget {
    height: 800px;
    width: 100%;
    background-color: #f0f0f0;
}
.canvas-widget .srd-node {
    position: relative;
}

/* 自定义节点和链接样式 */
.custom-node {
    /* 自定义节点样式 */
}

.custom-link {
    /* 自定义链接样式 */
}

.canvas-widget .srd-node:hover::after {
    visibility: visible;
    opacity: 1;
}
.code-viewer {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
    max-height: 600px;
    overflow: auto;
    padding: 15px;
}

/* 响应式设计 */
@media (max-width: 768px) {
    .canvas-widget {
        height: 300px;
    }
}
